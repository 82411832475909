<template>
  <div class="card-list">
    <div
      class="card-list-enable"
      :class="{ 'enable-show-disabled': showDisabled }"
    >
      <div
        class="card-item"
        :class="{ 'card-item-fade': accountId && c._id !== accountId }"
        v-for="(c, cIndex) in accountListEnable"
        :key="cIndex"
        :style="handleGetStyle(c)"
        @click="handleClickCard(c._id)"
      >
        <div class="card-left-bar"></div>

        <div class="card-left-logo-component">
          <div class="card-logo-circle-out">
            <div class="card-logo-circle-in">
              <img class="card-logo-img" :src="handleGetLogo(c)" alt="" />
            </div>
          </div>
        </div>

        <div class="card-names">
          <div class="card-customize-name">
            {{ c.name }}
          </div>
          <div class="card-agent-name">
            {{ handleGetAgentName(c) }}
          </div>
        </div>
        <div class="card-balance">
          <div class="count">{{ getDisplayAmount(c.balance) }}</div>
        </div>
      </div>
    </div>
    <div
      class="card-list-disable"
      :class="{ 'disable-show-disabled': showDisabled }"
    >
      <div class="disable-title" @click="showDisabled = !showDisabled">
        已停用的卡
      </div>
      <div class="disable-list">
        <div
          class="card-item"
          :class="{ 'card-item-fade': accountId && c._id !== accountId }"
          v-for="(c, cIndex) in accountListDisable"
          :key="cIndex"
          :style="handleGetStyle(c)"
          @click="handleClickCard(c._id)"
        >
          <div class="card-left-bar"></div>

          <div class="card-left-logo-component">
            <div class="card-logo-circle-out">
              <div class="card-logo-circle-in">
                <img class="card-logo-img" :src="handleGetLogo(c)" alt="" />
              </div>
            </div>
          </div>

          <div class="card-names">
            <div class="card-customize-name">
              {{ c.name }}
            </div>
            <div class="card-agent-name">
              {{ handleGetAgentName(c) }}
            </div>
          </div>
          <div class="card-balance">
            <div class="count">{{ getDisplayAmount(c.balance) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { agencyList } from "@/utils";
import { getDisplayAmount } from "@/utils/display.js";
export default {
  name: "AccountList",
  props: {
    accountList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    accountId: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  computed: {
    accountListEnable() {
      return this.accountList.filter((card) => card.status === 1);
    },
    accountListDisable() {
      return this.accountList.filter((card) => card.status === 0);
    },
  },
  data() {
    return {
      showDisabled: false,
    };
  },
  methods: {
    getDisplayAmount,
    /**
     * 生成卡片的style
     * @param {object} card
     * @returns 返回style字符串
     */
    handleGetStyle(card) {
      let style = "";
      const { agency } = card;
      const one = agencyList.find((item) => item.code === agency);

      if (one) {
        style = "background-color:" + one.color;
      } else {
        style = "background-color: #cccccc";
      }
      return style;
    },
    /**
     * 獲取卡片的logo
     * @param {object} card
     * @returns 返回logo，是img元素的src值
     */
    handleGetLogo(card) {
      let img = "";
      const { agency } = card;
      const one = agencyList.find((item) => item.code === agency);

      if (one) {
        img = one.logo;
      }
      return img;
    },
    /**
     * 獲取卡片的机构名
     * @param {object} card
     * @returns 返回机构名
     */
    handleGetAgentName(card) {
      let res = "";
      const { agency } = card;
      const one = agencyList.find((item) => item.code === agency);
      if (one) {
        res = one.name;
      }
      return res;
    },
    /**
     * 點擊了賬戶
     */
    handleClickCard(cardId) {
      this.$emit("handleClickCard", cardId);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-list {
  --card-height: 78px;
  --bar-width: 7px;
  --balance-width: 110px;

  --disable-show-height: 70%; // 停用卡的展示高度

  border: 1px solid rgb(226, 226, 226);
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  border-radius: 6px;
  user-select: none;
  overflow: hidden;
  .card-list-enable {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: all 0.6s;
  }
  .enable-show-disabled {
    height: calc(100% - var(--disable-show-height));
  }
  .card-list-disable {
    width: 100%;
    height: 0%;
    transition: all 0.6s;
    background-color: rgb(240, 240, 240);
    padding-top: 30px;
    position: relative;
    .disable-title {
      color: white;
      height: 30px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(151, 151, 151);
      border-radius: 0px 8px 0px 0px;
      position: absolute;
      top: -30px;
      left: 0px;

      transition: all 0.6s;
      cursor: pointer;
    }
    .disable-list {
      height: calc(100% - 30px - 10px);
      margin-top: 10px;
      overflow-y: auto;
    }
  }
  .disable-show-disabled {
    border-top: 1px solid rgb(194, 194, 194);
    height: var(--disable-show-height);
    .disable-title {
      border-radius: 7px;
      border-radius: 0px 0px 8px 0px;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  .card-item {
    width: 95%;
    height: var(--card-height);
    border-radius: 10px;
    margin: 0px auto;
    margin-bottom: 8px;
    margin-top: 8px;
    transition: all 0.3s;
    cursor: pointer;
    overflow: hidden;

    display: flex;
    .card-left-bar {
      width: var(--bar-width);
      background-color: white;
      opacity: 0.4;
    }
    .card-left-logo-component {
      width: var(--card-height);
      height: var(--card-height);
      display: flex;
      align-items: center;
      justify-content: center;
      .card-logo-circle-out {
        border-radius: 50%;
        overflow: hidden;
        width: 75%;
        height: 75%;
        background-color: rgba(255, 255, 255, 0.5);

        display: flex;
        align-items: center;
        justify-content: center;
        .card-logo-circle-in {
          border-radius: 50%;
          overflow: hidden;
          width: 85%;
          height: 85%;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          .card-logo-img {
            border-radius: 50%;
            overflow: hidden;
            width: 90%;
            height: 90%;
          }
        }
      }
    }
    .card-names {
      height: var(--card-height);
      width: calc(
        100% - var(--bar-width) - var(--card-height) - var(--balance-width)
      );
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      .card-customize-name {
        color: white;
        font-size: 16px;
        margin-bottom: 3px;
      }
      .card-agent-name {
        color: #ffffffaa;
        font-size: 13px;
        margin-top: 4px;
      }
    }
    .card-balance {
      border-left: 1px solid rgba(255, 255, 255, 0.408);
      height: 100%;
      width: var(--balance-width);
      display: flex;
      align-items: center;
      align-items: flex-end;
      .count {
        font-family: math;
        width: 100%;
        font-size: 20px;
        color: white;
        padding-right: 13px;
        padding-bottom: 12px;
        display: flex;
        justify-content: flex-end;
      }
      .input-with-select {
        width: 90px;
        margin: 0px auto;
        margin-bottom: 10px;
        .save-balance-icon {
        }
        .save-balance-icon:hover {
          color: #188cff;
          font-family: bold;
        }
      }
    }
  }
  .card-item-fade {
    opacity: 0.3;
  }
  .card-item:hover {
    box-shadow: 0px 0px 10px rgb(154, 154, 154);
  }
}
</style>
