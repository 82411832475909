<template>
  <el-dialog
    title="批量导入"
    :visible.sync="dialogVisible"
    width="1100px"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
  >
    <div class="batch-add-dialog-body">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="上传文件">
          <el-upload
            class="upload-component"
            action="/"
            accept=".xlsx"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-change="handleChange"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            :file-list="form.fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip upload-tip">
              只能上传xlsx文件
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文件预览">
          <el-table
            size="mini"
            :data="form.tableData"
            style="width: 100%"
            height="450"
            class="batch-table"
          >
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="date" label="日期">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.date }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.date"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="time" label="时间">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.time }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.time"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="accountName" label="账户">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.accountName }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.accountName"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="billTypeWord" label="账单类型">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.billTypeWord }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.billTypeWord"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="methodWord" label="收支">
              <template slot-scope="{ row }">
                <span
                  v-if="lock"
                  :class="{
                    'method-income': row.methodWord === '收入',
                    'method-spending': row.methodWord === '支出',
                  }"
                  >{{ row.methodWord }}</span
                >
                <el-input
                  v-if="!lock"
                  v-model="row.methodWord"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="description" label="描述">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.description }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.description"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额">
              <template slot-scope="{ row }">
                <span v-if="lock">{{ row.amount }}</span>
                <el-input
                  v-if="!lock"
                  v-model="row.amount"
                  size="mini"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-checkbox
        v-model="lock"
        style="margin-right: 20px"
        @change="handleChangeLock"
      >
        锁定
      </el-checkbox>

      <el-button @click="handleClose">取 消</el-button>

      <el-tooltip
        content="先锁定，才能检查"
        placement="top"
        :disabled="lock"
        effect="light"
      >
        <el-button type="warning" @click="check" :disabled="!lock">
          检 查
        </el-button>
      </el-tooltip>

      <el-tooltip
        content="先检查，才能导入"
        placement="top"
        :disabled="checkPass"
        effect="light"
      >
        <el-button
          type="primary"
          @click="save"
          :disabled="!checkPass"
          :loading="importLoading"
        >
          导 入
        </el-button>
      </el-tooltip>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import * as XLSX from "xlsx";

export default {
  name: "BatchAddDialog",
  data() {
    return {
      form: {
        fileList: [],
        tableData: [],
      },
      checkPass: false,
      lock: true,

      importLoading: false,
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    accountList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    billTypeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    /**
     * 关闭对话框前的回调
     */
    handleBeforeClose() {
      this.handleClose();
    },
    /**
     * 关闭对话框
     */
    handleClose() {
      ({
        form: this.form,
        checkPass: this.checkPass,
        lock: this.lock,
      } = this.$options.data());

      this.$emit("update:dialogVisible", false);
    },
    /**
     * 保存，开始导入
     */
    async save() {
      if (this.form.tableData.length) {
        const param = this.form.tableData.map((bill) => {
          const { billTypeWord, methodWord, ...newBill } = bill;
          billTypeWord;
          methodWord;
          return newBill;
        });
        this.importLoading = true;
        const res = await Api.BillApi.batchAdd(param).finally(() => {
          this.importLoading = false;
        });
        if (res && res.code === 0) {
          this.$message.success("批量新增成功");
          this.$emit("updateAccountList");
          this.$emit("updateBillList");
          this.handleClose();
        } else {
          this.$message.success("批量新增失败");
        }
      } else {
        this.$message.warning("表格没有数据");
      }
    },
    /**
     * 移除文件触发
     * @param {object} file 移除的文件
     * @param {array} fileList 文件列表
     */
    handleRemove(file, fileList) {
      file, fileList;
      // console.log("[handleRemove file, fileList]->", file, fileList);
      this.form.tableData = [];
      this.checkPass = false;
    },
    /**
     * 移除文件前的回调
     * @param {object} file 移除的文件
     */
    beforeRemove(file) {
      file;
      // console.log("[beforeRemove file]->", file);
    },
    /**
     * 上传组件文件变化
     * @param {object} file 上传的文件
     * @param {array} fileList 文件列表
     */
    handleChange(file, fileList) {
      fileList;
      const { name } = file;
      if (name.endsWith(".xlsx")) {
        this.checkPass = false;
        // console.log("[handleChange file, fileList]->", file, fileList);
        this.handleDisposeFileData(file);
      } else {
        this.form.fileList = [];
        this.$message.warning("文件格式不正确");
      }
    },
    /**
     * 处理文件数据到table里
     * @param {object} file 文件数据
     */
    handleDisposeFileData(file) {
      this.form.tableData = [];
      // console.log("[handleDisposeFileData]");
      // 获取到文件夹
      // let fileList = event.target.files;
      // 如果数据不为空
      if (file) {
        // 前言：FileReader是一种异步文件读取机制，结合input:file可以很方便的读取本地文件。
        let reader = new FileReader();
        // let file = this.form.fileList[0]; //拿到第一条数据
        // console.log("[file]->", file);
        reader.readAsBinaryString(file.raw); // 将文件以二进制形式读入页面
        // console.log(this); //这里的this指向 vue中的data
        // let _this = this; //把data里的数据赋值给新的变量
        // wb:wordbook 工作表
        reader.addEventListener("load", (e) => {
          // console.log(this); //FileReader实例对象
          var data = e.target.result; //读取成功后result中的数据
          // console.log("[data]->", data);
          var wb = XLSX.read(data, { type: "binary" }); //以base64方法读取 结果
          // console.log("[wb]->", wb);
          let sheetName = wb.SheetNames[0]; //是获取Sheets中第一个Sheet的名字
          // console.log("[sheetName]->", sheetName);
          let sheets = wb.Sheets[sheetName]; //wb.Sheets[Sheet名]获取第一个Sheet的数据
          // console.log("[sheets]->", sheets);
          //将数据解析为json字符串
          let dataList2 = JSON.stringify(XLSX.utils.sheet_to_json(sheets));
          // console.log("[dataList2]->", dataList2);
          let dataList3 = JSON.parse(dataList2); //讲json转为 数组的格式 看格式所需
          // console.log("[dataList3]->", dataList3);
          // _this.dataList = dataList3; //赋值
          dataList3.forEach((bill) => {
            // date: string; // 2022-10-21
            // time: string; // 1132
            // accountName: string; // 生活卡
            // billType: string; // b4e31231d8
            // method: string; // "spending", "income"
            // description: string; // "午餐"
            // amount: number; // 10

            const newBill = {
              date: "",
              time: "",
              accountName: "",
              billTypeWord: "",
              billType: "",
              methodWord: "",
              method: "",
              description: "",
              amount: "",
            };
            if (bill["日期"]) {
              newBill.date = bill["日期"];
            }
            if (bill["时间"]) {
              newBill.time = bill["时间"];
            }
            if (bill["账户"]) {
              newBill.accountName = bill["账户"];
            }
            if (bill["类型"]) {
              newBill.billTypeWord = bill["类型"];
            }
            if (bill["收支"]) {
              newBill.methodWord = bill["收支"];
            }
            if (bill["描述"]) {
              newBill.description = bill["描述"];
            }
            if (bill["金额"]) {
              newBill.amount = bill["金额"];
            }

            this.form.tableData.push(newBill);
          });
        });
      }
    },
    /**
     * 检查表格数据格式是否合规
     */
    check() {
      let errorList = [];
      this.form.tableData.forEach((bill, index) => {
        const lineNumber = index + 1;
        // bill
        const {
          date = "", // "2024-03-15"
          time = "", // "09:00:00"
          accountName = "", // "生活卡"
          amount = "", // "20"
          billTypeWord = "", // "早餐"
          // description = "", // "买了一袋苹果"
          methodWord = "", // "支出"
        } = bill;

        // 检查日期
        if (!date) {
          errorList.push(`第${lineNumber}行，存在缺乏日期`);
        } else {
          const reg1 = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
          const reg2 = /^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/;
          const result1 = reg1.test(date);
          const result2 = reg2.test(date);
          if (!result1 && !result2) {
            errorList.push(
              `第${lineNumber}行，日期格式应该为yyyy-mm-dd 或 yyyy/mm/dd`
            );
          } else {
            let dateSplit = [];
            if (result1) {
              dateSplit = date.split("-");
            } else if (result2) {
              dateSplit = date.split("/");
              bill.date = dateSplit.join("-");
            }

            const year = Number(dateSplit[0]);
            const month = Number(dateSplit[1]);
            const day = Number(dateSplit[2]);
            if (month < 1 || month > 12) {
              errorList.push(`第${lineNumber}行，月份应该在01 ~ 12`);
            }
            if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
              // 大月
              if (day < 1 || day > 31) {
                errorList.push(`第${lineNumber}行，大月时，日应该在01 ~ 31`);
              }
            } else if ([4, 6, 9, 11].includes(month)) {
              // 小月
              if (day < 1 || day > 30) {
                errorList.push(`第${lineNumber}行，小月时，日应该在01 ~ 30`);
              }
            } else {
              // 2月
              if (year % 4 === 0) {
                // 润月
                if (day < 1 || day > 29) {
                  errorList.push(`第${lineNumber}行，闰月时，日应该在01 ~ 29`);
                }
              } else {
                // 平月
                if (day < 1 || day > 28) {
                  errorList.push(`第${lineNumber}行，平月时，日应该在01 ~ 28`);
                }
              }
            }
          }
        }

        // 检查时间
        if (!time) {
          errorList.push(`第${lineNumber}行，存在缺乏时间`);
        } else {
          const reg1 = /^\d{2}:\d{2}:\d{2}/; // ^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$
          const result1 = reg1.test(time);
          const reg2 = /^\d{4}$/; // ^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$
          const result2 = reg2.test(time);
          if (!result1 && !result2) {
            errorList.push(
              `第${lineNumber}行，时间格式应该为 HH:MM:SS 或 HHMM`
            );
          }
          if (result1) {
            const timeSplit = time.split(":");
            const h = Number(timeSplit[0]);
            const m = Number(timeSplit[1]);
            const s = Number(timeSplit[2]);
            if (h < 0 || h >= 24) {
              errorList.push(`第${lineNumber}行，小时应该在 00 ~ 23`);
            }
            if (m < 0 || m >= 60) {
              errorList.push(`第${lineNumber}行，分钟应该在 00 ~ 59`);
            }
            if (s < 0 || s >= 60) {
              errorList.push(`第${lineNumber}行，秒应该在 00 ~ 59`);
            }
          }
          if (result2) {
            const hStr = time.slice(0, 2);
            const mStr = time.slice(2);
            const h = Number(hStr);
            const m = Number(mStr);
            if (h < 0 || h >= 24) {
              errorList.push(`第${lineNumber}行，小时应该在 00 ~ 23`);
            }
            if (m < 0 || m >= 60) {
              errorList.push(`第${lineNumber}行，分钟应该在 00 ~ 59`);
            }
            bill.time = `${hStr}:${mStr}:00`;
          }
        }

        // 检查账户
        if (!accountName) {
          errorList.push(`第${lineNumber}行，存在缺乏账户名`);
        } else {
          const accountOne = this.accountList.find(
            (acc) => acc.account === accountName || acc.name === accountName
          );
          if (!accountOne) {
            errorList.push(`第${lineNumber}行，存在找不到的账户名`);
          } else {
            if (accountOne.status === 0) {
              errorList.push(`第${lineNumber}行，存在停用的账户名`);
            } else if (accountOne.status === 1) {
              this.$set(bill, "accountId", accountOne._id);
              this.$set(bill, "accountName", accountOne.name);
            }
          }
        }

        // 检查类型
        if (!billTypeWord) {
          errorList.push(`第${lineNumber}行，存在缺乏分类名`);
        } else {
          let method = "";
          if (methodWord === "收入") {
            method = "income";
          } else if (methodWord === "支出") {
            method = "spending";
          }
          if (method) {
            const typeOne = this.billTypeList.find(
              (t) => t.name === billTypeWord && t.method === method
            );
            if (!typeOne) {
              errorList.push(
                `第${lineNumber}行，找不到该分类组合：([${billTypeWord}]+[${methodWord}])`
              );
            } else {
              this.$set(bill, "billType", typeOne.id);
              this.$set(bill, "method", method);
            }
          } else {
            errorList.push(`第${lineNumber}行，存在不明确的收、支类型`);
          }
        }

        // 检查金额
        if (!amount) {
          errorList.push(`第${lineNumber}行，没填金额`);
        } else {
          if (isNaN(amount)) {
            errorList.push(`第${lineNumber}行，金额不是数字`);
          } else {
            const numberAmount = Number(amount);
            if (numberAmount === 0) {
              errorList.push(`第${lineNumber}行，金额不能等于0`);
            } else if (numberAmount < 0) {
              bill.amount = -1 * numberAmount;
            } else {
              const reg = /^\d+(\.\d{1,2})?$/;
              if (!reg.test(numberAmount)) {
                errorList.push(`第${lineNumber}行，金额小数位不能超过2位`);
              }
            }
          }
        }
      });

      if (!errorList.length) {
        this.checkPass = true;
        this.$message.success("检查通过");
      } else {
        this.checkPass = false;
        this.$message({
          type: "warning",
          message:
            "检查不通过，原因:<br /><br />" +
            errorList.join("<br />") +
            "<br /><br />解除锁定以编辑",
          dangerouslyUseHTMLString: true,
          showClose: true,
          duration: 0,
        });
      }
    },
    /**
     * 切换锁定的checkbox
     * @param {Boolean} lock 切换后的值
     */
    handleChangeLock(lock) {
      if (!lock) {
        this.checkPass = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.batch-add-dialog-body {
  .upload-component {
    display: flex;
    align-items: center;
    .upload-tip {
      margin: 0px 30px 0px 10px;
      opacity: 0.7;
    }
    ::v-deep .el-upload-list {
      // border: 1px solid rgb(235, 235, 235);
      // border-radius: 4px;
      .el-upload-list__item {
        margin-top: 0px;
      }
    }
  }
  .batch-table {
    .method-income {
      color: #f56c6c;
    }
    .method-spending {
      color: #67c23a;
    }
  }
}
</style>
