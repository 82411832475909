export const agencyList = [{
    name: "中国工商银行",
    code: 'icbc',
    color: "#DF2020",
    logo: require('./../assets/image/accountCovers/single_logo_icbc.png')
  },
  {
    name: "中国建设银行",
    code: 'ccb',
    color: "#1c3cb2",
    logo: require('./../assets/image/accountCovers/single_logo_ccb.png')
  },
  {
    name: "中国银行",
    code: 'boc',
    color: "#BF0000",
    logo: require('./../assets/image/accountCovers/single_logo_boc.png')
  },
  {
    name: "支付宝",
    code: 'alipay',
    color: "#3994ED",
    logo: require('./../assets/image/accountCovers/single_logo_alipay.png')
  },
  {
    name: "微信钱包",
    code: 'wechatpay',
    color: "#37BD4B",
    logo: require('./../assets/image/accountCovers/single_logo_wechatpay.png')
  },
  {
    name: "招商银行",
    code: 'cmb',
    color: "#A61F23",
    logo: require('./../assets/image/accountCovers/single_logo_cmb.png')
  },
  {
    name: "中国邮政储蓄",
    code: 'psbc',
    color: "#108B3E",
    logo: require('./../assets/image/accountCovers/single_logo_psbc.png')
  },
  {
    name: "中国农业银行",
    code: 'abc',
    color: "#2d9b65",
    logo: require('./../assets/image/accountCovers/single_logo_abc.png')
  },
  {
    name: "兴业银行",
    code: 'cib',
    color: "#0e3d95",
    logo: require('./../assets/image/accountCovers/single_logo_cib.png')
  },
  {
    name: "现金",
    code: 'cash',
    color: "#ffae00",
    logo: require('./../assets/image/accountCovers/single_logo_cash.png')
  }
];