<template>
  <div class="component-container">
    <div class="content-container">
      <div class="bill-flow-left">
        <div class="bill-flow-left-header">账户列表</div>

        <div class="bill-flow-left-content">
          <AccountList :accountList="accountList" @handleClickCard="() => {}" />
        </div>
      </div>
      <div class="bill-flow-mid">
        <div class="bill-flow-mid-header">
          <el-button
            @click="handleMonthJump(-1)"
            size="small"
            :disabled="!date"
          >
            上个月
          </el-button>
          <el-date-picker
            v-model="date"
            align="right"
            type="month"
            placeholder="选择月份"
            value-format="yyyy-MM"
            @change="handleGetBillByFilter"
            size="small"
            :clearable="false"
            key="month"
          />
          <el-button @click="handleMonthJump(1)" size="small" :disabled="!date">
            下个月
          </el-button>
        </div>
        <!-- 串串组件 -->
        <BallSkewer
          :flowLoading="flowLoading"
          :billTypeList="billTypeList"
          :billList="billList"
          @handleEdit="handleEdit"
          @handleDelete="handleDelete"
        />
      </div>
      <div class="bill-flow-right">
        <div class="bill-flow-right-header">
          <span v-if="billForm.id">编辑</span>
          <span v-if="!billForm.id">新增</span>
          <div>
            <el-button @click="sortVisible = true" size="small" type="warning">
              排序
            </el-button>
            <el-button @click="batchAddDialogShow = true" size="small">
              批量导入
            </el-button>
          </div>
        </div>
        <div class="bill-flow-right-content">
          <el-form
            ref="add-bill-flow-form"
            label-suffix=":"
            :model="billForm"
            :rules="billFormRules"
            label-width="100px"
            class="add-bill-form"
          >
            <el-form-item label="日期" prop="date">
              <el-date-picker
                ref="form-item-date"
                v-model="billForm.date"
                align="right"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="时间" prop="time">
              <el-time-picker
                ref="form-item-time"
                v-model="billForm.time"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                value-format="HH:mm:ss"
                placeholder="请选择时间"
              >
              </el-time-picker>
            </el-form-item>

            <el-form-item label="账单类型" prop="billType">
              <div class="bill-type-container">
                <el-button
                  @click="typeDrawerShow = true"
                  style="margin-right: 16px"
                >
                  选择账单类型
                </el-button>

                <BillTypeItem
                  :billTypeData="selectedType"
                  :showClose="true"
                  v-if="selectedType"
                  @handleCloseBillType="handleCloseBillType"
                />
              </div>
            </el-form-item>
            <el-form-item label="收支类型">
              <el-tag type="success" v-if="billForm.method === 'spending'">
                支出
              </el-tag>
              <el-tag type="danger" v-if="billForm.method === 'income'">
                收入
              </el-tag>
            </el-form-item>

            <el-form-item label="账户" prop="accountId">
              <el-select
                v-model="billForm.accountId"
                placeholder="请选择账户"
                ref="form-item-accountId"
              >
                <el-option
                  v-for="(ac, acIndex) in accountList"
                  :key="acIndex"
                  :label="ac.name"
                  :value="ac._id"
                  :disabled="ac.status === 0"
                >
                  <div class="account-select-item" style="">
                    <img :src="handleGetLogo(ac)" style="" />
                    <div style="" class="account-select-item-name">
                      {{ ac.name }}
                    </div>
                    <div class="account-select-item-amount" style="">
                      {{ getDisplayAmount(ac.balance) }}
                    </div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="金额" prop="amount">
              <el-input
                v-model="billForm.amount"
                placeholder="请输入金额"
                ref="form-item-amount"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="账单内容" prop="description">
              <el-input
                v-model="billForm.description"
                placeholder="请输入内容"
                ref="form-item-description"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <div class="add-form-buttons">
            <el-button
              type="primary"
              @click="handleSave"
              :loading="saveLoading"
            >
              确 定 (ALT + ENTER)
            </el-button>
            <el-button type="" @click="handleClearForm">取 消</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      title="选择类型"
      :visible.sync="typeDrawerShow"
      direction="rtl"
      size="600px"
      @close="handleCloseDrawer"
      @opened="handleOpenedDrawer"
      class="type-drawer"
    >
      <div class="type-drawer-content">
        <div class="type-drawer-filter">
          <span class="drawer-filter-title"> 过滤搜索：</span>
          <el-input
            ref="typeQuickFilter"
            v-model="typeQuickFilter"
            placeholder="过滤"
            class="drawer-filter-input"
          />
        </div>
        <BillTypeSelector
          ref="bill-type-selector"
          :typeQuickFilter="typeQuickFilter"
          :billTypeIncome="billTypeIncome"
          :billTypeSpending="billTypeSpending"
          @set-type="handleSetBillType"
        />
      </div>
    </el-drawer>

    <!-- 批量导入的对话框 -->
    <BatchAddDialog
      :dialogVisible.sync="batchAddDialogShow"
      :accountList="accountList"
      :billTypeList="billTypeList"
      @updateAccountList="getAccountList"
      @updateBillList="handleGetBillByFilter"
    />
    <!-- 排序对话框 -->
    <BillSortDialog :sortVisible.sync="sortVisible" />
  </div>
</template>

<script>
import Api from "@/api";
import {
  spendingTypes,
  incomeTypes,
} from "../type-manage/component/defaultData.js";
import AccountList from "@/components/AccountList/AccountList.vue";
import BillTypeSelector from "@/components/BillTypeSelector/BillTypeSelector.vue";
import BillTypeItem from "@/components/BillTypeSelector/BillTypeItem.vue";
import BatchAddDialog from "@/components/BatchAddDialog/index.vue";
import BillSortDialog from "@/components/BillSortDialog/index.vue";
import BallSkewer from "./components/BallSkewer.vue";
import dayjs from "dayjs";
import { agencyList } from "@/utils";
import { getDisplayAmount } from "@/utils/display.js";

// import * as defaultTypeData from "@/views/type-manage/component/defaultData.js";
export default {
  name: "BillFlow",
  components: {
    AccountList,
    BallSkewer,
    BillTypeSelector,
    BillTypeItem,
    BatchAddDialog,
    BillSortDialog,
  },
  watch: {
    "billForm.billType": {
      handler(val) {
        if (val) {
          const typeOne = this.billTypeList.find((o) => o.id === val);
          if (typeOne) {
            this.billForm.method = typeOne.method;
          } else {
            this.billForm.method = "";
          }
        } else {
          this.billForm.method = "";
        }
      },
      deep: true,
      immediate: true,
    },
    "billForm.accountId": {
      handler(val) {
        if (val) {
          const accOne = this.accountList.find((o) => o._id === val);
          if (accOne) {
            this.billForm.accountName = accOne.name;
          } else {
            this.billForm.accountName = "";
          }
        } else {
          this.billForm.accountName = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      // left
      date: null,
      accountList: [],

      // mid
      billList: [],
      flowLoading: false,
      billTypeSpending: [],
      billTypeIncome: [],

      // right
      saveLoading: false,
      billForm: {
        id: "",
        accountId: "",
        accountName: "",
        billType: "",
        date: null,
        time: null,
        method: "",
        amount: null,
        description: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      typeDrawerShow: false,
      typeQuickFilter: "",

      billFormRules: {
        date: [
          {
            required: true,
            message: "请选择日期",
            trigger: ["change", "blur"],
          },
        ],
        time: [
          {
            required: true,
            message: "请选择日期",
            trigger: ["change", "blur"],
          },
        ],
        billType: [
          {
            required: true,
            message: "请选择账单类型",
            trigger: ["change", "blur"],
          },
        ],
        accountId: [
          {
            required: true,
            message: "请选择账户",
            trigger: ["change", "blur"],
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["change", "blur"],
          },
        ],
        description: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["change", "blur"],
          },
        ],
      },
      batchAddDialogShow: false,
      /** 排序对话框 */
      sortVisible: false,
    };
  },
  async created() {
    await this.getBillTypeList(); // 要获取完billtype，再去获取账单，这样才能显示出串串小球里的icon和颜色
    this.getAccountList();

    this.date = dayjs().format("YYYY-MM");
    this.handleGetBillByFilter();
  },
  mounted() {
    document.addEventListener("keydown", this.initListenKey);
  },
  computed: {
    billTypeList() {
      let all = [];
      const t_1 = JSON.parse(JSON.stringify(this.billTypeSpending));
      const t_2 = JSON.parse(JSON.stringify(this.billTypeIncome));
      all.push(...t_1);
      all.push(...t_2);
      return all;
    },
    selectedType() {
      let res = null;

      if (this.billForm.billType) {
        const one = this.billTypeList.find(
          (o) => o.id === this.billForm.billType
        );
        if (one) {
          res = JSON.parse(JSON.stringify(one));
        }
      }

      return res;
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.initListenKey);
  },
  methods: {
    getDisplayAmount,
    /**
     * 獲取卡片的logo
     * @param {object} card
     * @returns 返回logo，是img元素的src值
     */
    handleGetLogo(card) {
      let img = "";
      const { agency } = card;
      const one = agencyList.find((item) => item.code === agency);

      if (one) {
        img = one.logo;
      }
      return img;
    },
    /**
     * 初始化本页的键盘按钮监听
     * @param {Object} e 键盘事件
     */
    initListenKey(e) {
      //定义一个变量 方便后面保存 事件对象
      let $Event = undefined;
      // 将事件对象保存
      $Event = e;
      // // 将用户按下的键同义转换成大写
      const keyCode = $Event.key.toLocaleUpperCase();
      // const ctrlKey = $Event.ctrlKey || e.metaKey;
      const altKey = $Event.altKey;
      // //保存 用户按下的键，方便后续出传值
      if (altKey) {
        if (["N", "T", "ENTER"].includes(keyCode)) {
          this.handleHotKey(`ALT-${keyCode}`);
          $Event.preventDefault();
        }
      } else {
        if (this.typeDrawerShow) {
          if (
            [
              "ARROWUP",
              "ARROWDOWN",
              "ARROWLEFT",
              "ARROWRIGHT",
              "ENTER",
            ].includes(keyCode)
          ) {
            this.handleHotKey(keyCode);
            $Event.preventDefault();
          }
        }
      }
    },
    /**
     * 处理各种本页面里的键盘按键事件
     * @param {string} code 键盘的按键，为大写，如果带有alt，如alt+n，则为"ALT-N"
     */
    handleHotKey(code) {
      if (code === "ENTER") {
        if (this.typeDrawerShow) {
          // 如果是选择账单类型框框弹出时，则选中账单类型
          if (this.$refs["bill-type-selector"]) {
            this.$refs["bill-type-selector"].handleEnter();
          }
        }
      } else if (code === "ALT-ENTER") {
        if (!this.typeDrawerShow) {
          // 如果不是选择账单类型框框弹出时，则回车保存
          this.handleSave();
        }
      } else if (code === "ALT-N") {
        this.$refs["form-item-date"].focus();
        this.billForm.date = dayjs().format("YYYY-MM-DD");
      } else if (code === "ALT-T") {
        this.typeDrawerShow = true;
      } else if (
        ["ARROWUP", "ARROWDOWN", "ARROWLEFT", "ARROWRIGHT"].includes(code)
      ) {
        if (this.$refs["bill-type-selector"]) {
          this.$refs["bill-type-selector"].handleArrow(code);
        } else {
          console.log(
            "this.$refs['bill-type-selector']",
            "no refs to handleArrow",
            code
          );
        }
      } else {
        this.$message.success(code);
      }
    },
    handleMonthJump(type) {
      if (this.date) {
        const dateArr = this.date.split("-").map((item) => Number(item));
        let year = dateArr[0];
        let month = dateArr[1];
        if (type === 1) {
          month += 1;
          if (month === 13) {
            year += 1;
            month = 1;
          }
        } else if (type === -1) {
          month -= 1;
          if (month === 0) {
            year -= 1;
            month = 12;
          }
        }
        console.log(year + "-" + month);
        let d = "";
        if (month < 10) {
          d = year + "-0" + month;
        } else {
          d = year + "-" + month;
        }
        this.date = d;
        this.handleGetBillByFilter();
      }
    },
    /**
     * 获取所有账单类型
     */
    async getBillTypeList() {
      this.billTypeSpending = [];
      this.billTypeIncome = [];

      const res = await Api.BillTypeApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        records.forEach((item) => (item.id = item._id));
        // 分类的types
        this.billTypeSpending = JSON.parse(JSON.stringify(spendingTypes));
        this.billTypeIncome = JSON.parse(JSON.stringify(incomeTypes));
        const _incomeCustomize = records.filter(
          (item) => item.method === "income"
        );
        const _spendingCustomize = records.filter(
          (item) => item.method === "spending"
        );
        _incomeCustomize.forEach((item) => (item.id = item._id));
        _spendingCustomize.forEach((item) => (item.id = item._id));
        this.billTypeIncome.push(..._incomeCustomize);
        this.billTypeSpending.push(..._spendingCustomize);
      } else {
        this.$message.error("获取所有类型失败");
      }
    },
    handleUpdateBillAndAccount() {
      this.handleGetBillByFilter();
      this.getAccountList();
    },
    /**
     * 获取账户列表
     */
    async getAccountList() {
      const res = await Api.AccountApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        this.accountList = records || [];
      } else {
        this.$message.error("获取账户列表失败");
      }
    },
    /**
     * 按照月份获取账单
     */
    async handleGetBillByFilter() {
      this.flowLoading = true;
      const param = {
        date: "",
        accountId: undefined,
        startDate: "",
        endDate: "",
      };
      // if (Array.isArray(this.date)) {
      //   param.startDate = this.date[0];
      //   param.endDate = this.date[1];
      // } else {
      //   param.date = this.date;
      // }

      param.date = this.date;
      const res = await Api.BillApi.queryBillByTimeFilter(param).finally(() => {
        this.flowLoading = false;
      });
      if (res && res.code === 0) {
        const { data } = res;
        this.billList = data.records || [];
      } else {
        this.$message.error("按照月份获取账单失败");
      }
    },
    /**
     * 删除了账单类型
     */
    handleCloseBillType() {
      this.billForm.billType = "";
    },
    /**
     * 选择了账单类型
     * @param {string} typeId 类型id
     */
    handleSetBillType(typeId) {
      this.billForm.billType = typeId;
      this.typeDrawerShow = false;
    },
    /**
     * 点击编辑
     * @param {object} row 行数据
     */
    handleEdit(row) {
      this.billForm.id = row._id;
      this.billForm.accountId = row.accountId;
      this.billForm.accountName = row.accountName;
      this.billForm.billType = row.billType;
      this.billForm.date = row.date;
      this.billForm.time = row.time;
      this.billForm.method = row.method;
      this.billForm.amount = row.amount;
      this.billForm.description = row.description;
    },
    /**
     * 删除账单
     * @param {string} id 账单id
     */
    async handleDelete(id) {
      this.$confirm("是否要删除这一账单？", "提示", {
        confirmButtonText: "确定，删除账单",
        cancelButtonText: "取消，保留账单",
      })
        .then(async () => {
          const res = await Api.BillApi.removeSync(id);
          if (res && res.code === 0) {
            const { data } = res;
            if (data === true) {
              this.$message.success("删除成功");
            } else {
              this.$message.error("删除失败");
            }
            this.handleUpdateBillAndAccount();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.$message.info("取消了删除账单");
        });
    },
    /**
     * 保存
     */
    handleSave() {
      this.$refs["add-bill-flow-form"].validate(async (valid) => {
        if (valid) {
          const billDto = {
            date: this.billForm.date, // 2022-10-21
            time: this.billForm.time, // 11:32:22
            billType: this.billForm.billType, // b4e31231d8
            method: this.billForm.method, // "spending", "income"
            accountId: this.billForm.accountId, // a1b2c3d4
            amount: Number(this.billForm.amount), // 10
            description: this.billForm.description, // "午餐"
            accountName: this.billForm.accountName, // 生活卡
          };

          this.saveLoading = true;
          if (this.billForm.id) {
            const res = await Api.BillApi.updateSync({
              id: this.billForm.id,
              ...billDto,
            }).finally(() => {
              this.saveLoading = false;
            });
            if (res && res.code === 0) {
              this.$message.success("编辑成功");
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const res = await Api.BillApi.addSync(billDto).finally(() => {
              this.saveLoading = false;
            });
            if (res && res.code === 0) {
              this.$message.success("保存成功");
            } else {
              this.$message.error("保存失败");
            }
          }

          this.handleClearForm();
          this.handleUpdateBillAndAccount();
        } else {
          this.$message.warning("请完善表单");
        }
      });
    },
    handleClearForm() {
      ({billForm: this.billForm} = this.$options.data());
      this.$refs["form-item-date"].blur();
      this.$refs["form-item-time"].blur();
      this.$refs["form-item-accountId"].blur();
      this.$refs["form-item-amount"].blur();
      this.$refs["form-item-description"].blur();
      this.$nextTick(() => {
        this.$refs["add-bill-flow-form"].resetFields();
      });
    },

    /**
     * 關閉抽屜
     */
    handleCloseDrawer() {
      this.resetBillTypeSelector();
      // done();
    },
    /**
     * 打开抽屉完成之后的回调
     */
    handleOpenedDrawer() {
      // focus
      this.$refs["typeQuickFilter"].focus();
    },
    /**
     * 重置抽屜數據
     */
    resetBillTypeSelector() {
      ({
        typeDrawerShow: this.typeDrawerShow,
        typeQuickFilter: this.typeQuickFilter,
      } = this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  .bill-flow-left {
    height: 100%;
    width: 25%;
    .bill-flow-left-header {
      height: 50px;
      border-bottom: 1px solid rgb(221, 221, 221);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
    }
    .bill-flow-left-content {
      height: calc(100% - 50px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .bill-flow-mid {
    height: 100%;
    border-left: 2px solid rgb(219, 219, 219);
    border-right: 2px solid rgb(223, 223, 223);
    width: 38%;
    // justify-content: center;

    .bill-flow-mid-header {
      height: 50px;
      border-bottom: 1px solid rgb(221, 221, 221);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
    }
  }
  .bill-flow-right {
    height: 100%;
    width: 37%;
    .bill-flow-right-header {
      height: 50px;
      border-bottom: 1px solid rgb(221, 221, 221);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
    }
    .bill-flow-right-content {
      .add-bill-form {
        width: 80%;
        margin: 0px auto;
        margin-top: 60px;
        .bill-type-container {
          display: flex;
          align-items: center;
        }
      }
      .add-form-buttons {
        margin-top: 40px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.type-drawer {
  .type-drawer-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .type-drawer-filter {
      height: 60px;
      .drawer-filter-title {
        margin: 0px 0px 10px 10px;
      }
      .drawer-filter-input {
        width: 400px;
        margin: 0px 10px 10px 0px;
      }
    }
  }
}

.account-select-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    width: 30px;
    height: 30px;
    margin-left: 0px;
  }
  .account-select-item-name {
    width: 80px;
    margin-left: 6px;
  }
  .account-select-item-amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
  }
}
</style>
