<template>
  <div class="flow-container" v-loading="flowLoading">
    <div
      class="bill-ball-container"
      v-for="(ball, bIndex) in billList"
      :key="bIndex"
      :class="{
        'bill-ball-container-showdate': !(
          bIndex > 0 && ball.date === billList[bIndex - 1].date
        ),
        'bill-ball-container-first': bIndex === 0,
        'bill-ball-container-last': bIndex === billList.length - 1,
      }"
    >
      <div class="bill-ball" :style="handleGetBallDetail(ball, 'color')">
        <i class="bill-ball-icon" :class="handleGetBallDetail(ball, 'icon')" />
      </div>
      <div class="ball-stick" />
      <div
        class="ball-type-and-amount ball-type-and-amount-left"
        v-if="ball.method === 'spending'"
      >
        <div class="bill-type">
          {{ handleGetBallDetail(ball, "name") }}
        </div>
        <div class="ball-amount">{{ getDisplayAmount(ball.amount) }}</div>
      </div>
      <div
        class="ball-type-and-amount ball-type-and-amount-right"
        v-if="ball.method === 'income'"
      >
        <div class="ball-amount">{{ getDisplayAmount(ball.amount) }}</div>
        <div class="bill-type">
          {{ handleGetBallDetail(ball, "name") }}
        </div>
      </div>
      <div
        class="ball-description ball-description-left"
        v-if="ball.method === 'income'"
      >
        {{ ball.description }}
      </div>
      <div
        class="ball-description ball-description-right"
        v-if="ball.method === 'spending'"
      >
        {{ ball.description }}
      </div>
      <div
        class="bill-date-container"
        v-if="!(bIndex > 0 && ball.date === billList[bIndex - 1].date)"
      >
        <div class="bill-date-sum bill-date-sum-left">
          <div class="bill-date-sum-number">
            {{ getDisplayAmount(getDailyTotal(billList, ball.date).income) }}
          </div>
          <div class="bill-date-sum-sign">收</div>
        </div>

        <div class="ball-date">
          <div class="ball-date-mmdd">
            {{ ball.date.slice(5) }}
          </div>
          <div class="ball-date-day">
            {{ handleGetDayOfWeek(ball.date) }}
          </div>
        </div>

        <div class="bill-date-sum bill-date-sum-right">
          <div class="bill-date-sum-sign">支</div>
          <div class="bill-date-sum-number">
            {{ getDisplayAmount(getDailyTotal(billList, ball.date).spending) }}
          </div>
        </div>
        <div class="date-stick" />
      </div>
      <div class="ball-buttons">
        <div class="ball-button" @click="handleEdit(ball)">
          <i class="el-icon-edit-outline edit-icon" />
        </div>
        <div class="ball-button" @click="handleDelete(ball._id)">
          <i class="el-icon-delete delete-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDisplayAmount, getDailyTotal } from "@/utils/display.js";
import dayjs from "dayjs";
export default {
  name: "BallSkewer",
  props: {
    flowLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    billTypeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    billList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    getDisplayAmount,
    getDailyTotal,
    handleGetBallDetail(ball, key) {
      const typeItem = this.billTypeList.find(
        (item) => item.id === ball.billType
      );
      let res = ``;
      if (typeItem) {
        const { color = "", icon = "", name = "" } = typeItem;
        if (key === "color" && color) {
          res = `background-color: ${color}`;
        } else if (key === "icon" && icon) {
          res = icon;
        } else if (key === "name" && name) {
          res = name;
        }
      }

      return res;
    },
    handleGetDayOfWeek(date) {
      let res = "";
      const day = dayjs(date).day();
      if (day === 0) {
        res = "日";
      } else if (day === 1) {
        res = "一";
      } else if (day === 2) {
        res = "二";
      } else if (day === 3) {
        res = "三";
      } else if (day === 4) {
        res = "四";
      } else if (day === 5) {
        res = "五";
      } else if (day === 6) {
        res = "六";
      }
      return res;
    },
    handleEdit(ball) {
      this.$emit("handleEdit", ball);
    },
    handleDelete(id) {
      this.$emit("handleDelete", id);
    },
  },
};
</script>

<style scoped lang="scss">
.flow-container {
  --ball-size: 50px; // 小球的尺寸
  --ball-margin: 15px; // 左右物体距离小球的距离

  --bill-date-container-height: 40px; // 日期和日收支的总高度
  --ball-date-height: 20px; // 日期的高度
  --ball-date-width: 77px; // 日期的宽度

  overflow-y: auto;
  height: calc(100% - 50px); // 50px是头部翻月器的高度，在父级设定

  .bill-ball-container {
    height: 90px;
    width: 100%;
    position: relative;

    --base-point-L: 50%;
    --base-point-T: 50%;
    .bill-ball {
      height: var(--ball-size);
      width: var(--ball-size);
      border-radius: 50%;
      background-color: rgb(255, 196, 0);
      position: absolute;
      left: var(--base-point-L);
      top: var(--base-point-T);
      transform: translate(
        calc(-0.5 * var(--ball-size)),
        calc(-0.5 * var(--ball-size))
      );
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      .bill-ball-icon {
        font-size: calc(var(--ball-size) * 0.4);
        color: white;
      }
    }
    .ball-stick {
      height: 100%;
      width: 2px;
      background-color: rgb(194, 194, 194);
      position: absolute;
      left: var(--base-point-L);
      top: 0px;
      transform: translate(-50%, 0px);
      z-index: 1;
    }
    .ball-type-and-amount {
      --ball-type-name-height: 17px;

      position: absolute;
      height: var(--ball-type-name-height);

      bottom: var(--base-point-T);

      display: flex;
      align-items: center;

      .bill-type {
        font-size: var(--ball-type-name-height);
        line-height: var(--ball-type-name-height);
      }
      .ball-amount {
        margin: 2px 12px 0px 12px;

        font-size: var(--ball-type-name-height);
        line-height: var(--ball-type-name-height);

        font-family: math;
      }
    }
    .ball-type-and-amount-left {
      left: calc(
        var(--base-point-L) + 0.5 * var(--ball-size) + var(--ball-margin)
      );
    }
    .ball-type-and-amount-right {
      right: calc(
        var(--base-point-L) + 0.5 * var(--ball-size) + var(--ball-margin)
      );
    }
    .ball-description {
      position: absolute;

      top: calc(var(--base-point-T) + 2.4px);
      color: #787878;
      font-size: 13px;
    }
    .ball-description-left {
      right: calc(
        var(--base-point-L) + 0.5 * var(--ball-size) + var(--ball-margin)
      );
    }
    .ball-description-right {
      left: calc(
        var(--base-point-L) + 0.5 * var(--ball-size) + var(--ball-margin)
      );
    }
    .bill-date-container {
      height: var(--bill-date-container-height);
      width: 100%;

      position: absolute;
      // left: calc(var(--base-point-L) - 0.5 * var(--ball-date-width));
      display: flex;
      justify-content: center;
      align-items: center;
      top: calc(0px - 1 * var(--bill-date-container-height) - 1px);
      .bill-date-sum {
        height: var(--ball-date-height);
        width: calc(50% - 0.5 * var(--ball-date-width));
        font-size: 11px;
        display: flex;
        align-items: center;
        .bill-date-sum-sign {
          color: #919191;
          margin: 0px 3px;
        }
        .bill-date-sum-number {
          font-family: math;
        }
      }
      .bill-date-sum-left {
        justify-content: flex-end;
        .bill-date-sum-number {
          color: rgba(255, 0, 0, 0.512);
        }
      }
      .bill-date-sum-right {
        justify-content: flex-start;
        .bill-date-sum-number {
          color: rgba(0, 128, 0, 0.58);
        }
      }
      .ball-date {
        border: 1px solid rgb(184, 184, 184);
        border-radius: calc(var(--ball-date-height) * 0.5);
        background-color: #fff;
        color: rgb(147, 147, 147);
        z-index: 1;

        height: var(--ball-date-height);
        width: var(--ball-date-width);

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0px 4px;
        .ball-date-mmdd {
          font-size: calc(var(--ball-date-height) * 0.7);
          line-height: calc(var(--ball-date-height) * 0.7);
          height: calc(var(--ball-date-height) * 0.7);
        }
        .ball-date-day {
          margin-left: 5px;
          font-size: calc(var(--ball-date-height) * 0.7);
          line-height: calc(var(--ball-date-height) * 0.7);
          height: calc(var(--ball-date-height) * 0.7);
        }
      }

      .date-stick {
        position: absolute;

        width: 2px;
        height: calc(100% + 1px);
        background-color: rgb(194, 194, 194);
        z-index: 0;
      }
    }
    .ball-buttons {
      --ball-buttons-height: 40px;
      position: absolute;
      height: var(--ball-buttons-height);
      width: 70px;
      top: calc(var(--base-point-T) - var(--ball-buttons-height) * 0.5);
      right: 40px;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ball-button {
        width: calc(var(--ball-buttons-height) * 0.83);
        height: calc(var(--ball-buttons-height) * 0.83);
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        > i {
          font-size: calc(var(--ball-buttons-height) * 0.4);
        }
        .edit-icon {
          color: #424242;
        }
        .delete-icon {
          color: #424242;
        }
      }
      .ball-button:hover {
        background-color: #ebebeb;
        .edit-icon {
          color: #188cff;
        }
        .delete-icon {
          color: #ff2626;
        }
      }
    }
  }
  .bill-ball-container-first {
    margin-top: 70px !important;
    .ball-stick {
      // height: 50%;
      // top: 50%;
    }
    .bill-date-container {
      .date-stick {
        top: 50%;
        height: calc(50% + 1px);
      }
    }
  }
  .bill-ball-container-last {
    margin-bottom: 80px;
    .ball-stick {
      height: 50%;
    }
  }
  .bill-ball-container:hover {
    .ball-buttons {
      visibility: visible;
    }
  }
  .bill-ball-container-showdate {
    margin-top: var(--bill-date-container-height);
  }
}
</style>
